@font-face {
  font-family: 'BullText-Medium';
  src: url('./utils/fonts/bulltext/BullText-Medium.woff2') format('woff2'),
       url('./utils/fonts/bulltext/BullText-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


body {
  background-image: url('./utils/images/background.jpg'); /* Pfad zum Hintergrundbild */
  background-size: cover; /* Hintergrundbild wird proportioniert skaliert, um das gesamte Element zu bedecken */
  background-repeat: no-repeat; /* Hintergrundbild wird nicht wiederholt */
  /*background-position: center bottom;*/ /* Für Background_old die Einstellung*/
  background-position: right center;
  background-attachment: fixed; /* Hintergrundbild bleibt beim Scrollen fixiert */
  font-family: 'BullText-Medium', sans-serif;
}


.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 100% der Viewport-Höhe */
  width: 100vw; /* 100% der Viewport-Breite */
  background-color: #f0f0f0; /* Hintergrundfarbe nach Wahl */
}

a {
  color: #175ACA; /* Farbe der Links */
  text-decoration: none; /* Optional: Entfernt die Unterstreichung */
}

a:hover {
  text-decoration: underline; /* Optional: Fügt eine Unterstreichung beim Hover hinzu */
}
