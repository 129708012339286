/* Logo.css */
.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo-container img {
    max-width: 100%; /* Stellt sicher, dass das Logo innerhalb des Containers passt */
    height: auto; /* Behält das Seitenverhältnis bei */
  }
  