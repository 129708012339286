.registration-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .registration-form input {
    padding: 8px; 
    margin: 0; /* Stellt sicher, dass kein zusätzlicher Außenabstand vorhanden ist */
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .registration-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 16px; /* Setzt den Abstand zwischen den Elementen auf 16px */
    width: 300px;   
  }
  
  .registration-form-logo-placeholder {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 20px;
  }

  input:first-of-type {
    margin-top: 0px; /* Erhöht den Abstand zum oberen Rand oder zum vorherigen Element */
  }
  
  
  input, select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  


  /* Standardmäßig leicht grau */
select.not-selected {
  color: #757575; /* Leichtes Grau */
}

/* Schwarz, wenn ein Element ausgewählt wurde */
select.selected {
  color: black;
}

/* Grau für die Optionen, um Konsistenz zu gewährleisten */
select option {
  color: black;
}

.form-error {
  color: #F50B48;  /*Redbull Rot Farbcode*/
  margin-top: 10px;
  text-align: center;
}
.options-container {
  position: absolute;
  z-index: 1000;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
}

.dropdown-container {
  position: relative; /* Neuer Bezugspunkt für die absolute Positionierung */
}

.options-container {
  position: absolute;
  z-index: 1000;
  width: 100%; /* Breite entsprechend dem Eingabefeld */
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
  top: 100%; /* Positioniert direkt unter dem Eingabefeld */
  left: 0;
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
}

.dropdown-option:hover {
  background-color: #f2f2f2;
}

.input-error, .select-error__control {
  border: 1px solid #F50B48 !important;
}

.select-error__control {
  border-color: #F50B48 !important;
}


.input-error, .select-error__control {
  border: 1px solid #F50B48 !important;
}

.select-error__control {
  border-color: #F50B48 !important;
}

.registration-form input, 
.registration-form select, 
.registration-form button, 
.registration-form textarea {
  color: #000F1E;
  font-family: 'BullText-Medium', sans-serif;
  font-size: 14px; /* Setzt die Schriftgröße */
  font-weight: normal; /* Stellt sicher, dass der Text nicht fett ist */
}

/* Setzt die Placeholder-Farbe */
.registration-form input::placeholder, 
.registration-form textarea::placeholder {
  color: rgba(0, 15, 30, 0.4);
}

.registration-form .button {
  background-color: #001c39;
  color: #FFFFFF;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-footer {
  text-align: center;
  margin-top: 20px; 
}

.account-hint {
  margin-top: 10px; /* Reduzierter Wert */
  text-align: center; 
}

.account-text-container {
  position: relative;
  padding-top: 20px; /* Stellt sicher, dass genug Platz für den "Sign-Up" Text ist */
}

.signup-text {
  top: 10px; /* Positioniert den Text 10px über dem oberen Rand des Containers */
  left: 0;
  margin: 0; /* Entfernt möglicherweise vorhandene Standardmargen */
  font-size: 18px; 
  text-align: center; 
}