@font-face {
    font-family: 'Bull-Bold';
    src: url('../utils/fonts/bull/Bull-Bold.woff2') format('woff2'),
         url('../utils/fonts/bull/Bull-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }


.registration-false-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* Styling spezifisch für die Erfolgsmeldung */
.registration-false {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 300px;
    height: 300px;
    text-align: center; /* Stellt sicher, dass der Text zentriert ist */
}

.logo-placeholder {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styling für die Erfolgsmeldung und den Link */
.false-message {
    color: #000F1E;
    font-size: 16px;
}
.success-message strong {
    display: block; /* Macht es zu einem Block-Element */
    margin-bottom: 10px; /* Fügt einen unteren Abstand hinzu */
}

.bold-text {
    font-family: 'Bull-Bold', sans-serif;
  }

